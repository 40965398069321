import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";
import PressKit01 from "../img/press-kit-01.jpg";
import PressKit02 from "../img/press-kit-02.jpg";
import PressKit03 from "../img/press-kit-03.jpg";
import PressKit04 from "../img/presskitlogo.svg";
import Brand_Guideline_OiC from "./Brand_Guideline_OiC.pdf"
export default function PressKit() {
  return (
    <div>
      <Helmet>
        <title>OIC - Wholesaler</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
      </Helmet>
      <Layout>
        <div
          style={{
            background: "#03A5A5",
          }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 text-center"
                style={{
                  paddingTop: "120px",
                  color: "#fff",
                  paddingBottom: "120px",
                }}
              >
                <h1>
                  <span>BRAND ASSETS</span>
                </h1>
                <p>
                  OiC is a B2B Platform for optical retailers that connects
                  Brands, Distributors and consumers with the retailers and
                  drives business through an exceptional multi vendor platform.
                </p>
                <a href={Brand_Guideline_OiC} className="hvr-forward downloadBrand">
                  Download The Complete Kit
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="pressCard">
                  <img src={PressKit01} className="img-responsive"></img>{" "}
                  <br></br>
                  <br></br>
                  <h4>Leadership Photos</h4>
                  <p>
                    Check out high-resolution photographs of our courageous
                    leadership team – which includes executives from around the
                    company for blogs, speaking engagements.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="pressCard">
                  <img src={PressKit03} className="img-responsive"></img>{" "}
                  <br></br> <br></br>
                  <h4>Product Screenshots</h4>
                  <p>
                    Have a look at those screenshots? We've got screenshots for
                    your reference. On the web, smartphone, and tablet, get
                    high-resolution assets of the OIC interface.
                  </p>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="pressCard">
                  <img src={PressKit02} className="img-responsive"></img>{" "}
                  <br></br> <br></br>
                  <h4>Our People And Offices</h4>
                  <p>
                    OIC comprises dedicated and passionate individuals who,
                    following the footsteps of the founder, have embarked on a
                    mission to deliver a world-class product from India.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blueBackground padding60">
          <div className="container">
            <div className="row aligner">
              <div className="col-md-6">
                <h3>LOGO</h3>
                <p>
                  The OIC marks include the company's name and logo, as well as
                  any word, term, image, or other designation that identifies
                  the source or origin of OIC's products. Please do not alter
                  the marks or use them in any way that can cause confusion,
                  such as by implying sponsorship or endorsement by OIC, or by
                  confusing OIC with another brand (including your own).
                </p>
              </div>
              <div className="col-md-6">
                <img src={PressKit04} className="img-responsive"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#fff" }}>
          <div className="container" style={{background:"#03A5A5", borderRadius:"10px"}}>
            <div className="row aligner">
                
              <div className="col-md-12 text-center"><br></br><br></br>
                <h1 style={{fontWeight:"bold", color:"#fff"}}>GET THE COMPLETE MEDIA KIT</h1>
              </div>
            <div className="col-md-12 text-center">
                <br></br>
            <a href={Brand_Guideline_OiC} className="downloadBrand hvr-forward">
                  Download
                </a><br></br><br></br>
            </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container">
            <div className="row aligner">
              <div className="col-md-7">
                <h1>Didn't Find What You're Looking For? Get In Touch.</h1>
              </div>
              <div className="col-md-5">
                <div className="mailBackground">
                  <i className="fa fa-envelope"></i> press@oicapps.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
