import React from "react"

const Testimonail = () =>{
    return(
        <div className="" style={{ background: "#ECEDEF" }}>
          <div className="container SuperWidth" data-aos="fade-left" data-aos-duration="400">
            <div className="row padding60">
              <div className="col-md-12  text-center">
                <h5>TESTIMONIAL</h5>
                <h2>Client Feedback</h2>
                <br></br>
                <br></br>
              </div>
              <div className="col-md-4">
                <div className="testimonialCard ">
                  <p>
                    I Loved the whole platform.As a brand it helped us reach to
                    maximum retailer and also help us organise and expand our
                    base
                  </p>
                  <br></br>
                  <div className="testimonialCardAuthor">
                  
                    <span>
                      Joe Matthews<br></br>
                      <b>Joe Opticals</b>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonialCard super">
                  <p>
                    OiC new innovative application will make this optical
                    industry move towards, profitable attractive position, The
                    end customer also will be really happy, I sincerely wish all
                    the Opticians, Manufactures, Brands to support OiC's mission
                    and vision
                  </p>
                  <br></br>
                  <div className="testimonialCardAuthor">
                  
                    <span>
                      Joan Gray<br></br>
                      <b>Joan Opticals</b>
                    </span>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-4">
                <div className="testimonialCard">
                  <p>
                    Being an optical distributor, enjoying the OiC platform that
                    helps to reach the right retailer and highly recommended.
                  </p>
                  <br></br>

                  <div className="testimonialCardAuthor">
                  
                    <span>
                      Johnny Oliver<br></br>
                      <b>Johnny Opticals</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Testimonail;